import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import ImgStageProjects from '../images/svgs/stage_projekte.svg'
import '../components/PortfolioTeaser/PortfolioTeaser.css'
import ContactBoxSmall from '../components/ContactBoxSmall/ContactBoxSmall'
import ImgRefL1 from '../images/ref-l-1@2x.jpg'
import ImgRefL2 from '../images/ref-l-2@2x.jpg'
import ImgRefL3 from '../images/ref-l-3@2x.jpg'
import ImgRefR1 from '../images/ref-r-1@2x.jpg'
import ImgRefR2 from '../images/ref-r-2@2x.jpg'
import ImgRefR3 from '../images/ref-r-3@2x.jpg'
import ImgRefM1 from '../images/ref-m-1@2x.jpg'

const ProjectsPage = ({ data }) => {
  const { slug, titleStage, titleIntrotext } = data.contentfulProjectsOverview
  const content_shortStageIntroText = data.contentfulProjectsOverview.childContentfulProjectsOverviewShortStageIntroTextTextNode.shortStageIntroText
  const content_shortIntrotext = data.contentfulProjectsOverview.childContentfulProjectsOverviewShortIntrotextTextNode.shortIntrotext
  
  return (
    <Layout>
      <SEO title='Projekte' />
      <Header titleBig={titleStage} stageIntroText={content_shortStageIntroText} color='grey' className="stage-projects"><ImgStageProjects/></Header>
      <div className='flex w-full xs:mb-0 xs:mt-16 md:mb-16 lg:my-24 xl:my-32 px-6 md:px-8 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap'>
          <div className='flex flex-col xs:order-2 md:order-1 pr-8 lg:pr-32 md:w-8/12 max-w-4xl '>
            <p className='leading-relaxed'>{content_shortIntrotext}</p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-4/12 xs:mb-8 md:mb-0 max-w-4xl '>
            <h2 className='leading-relaxed xl:w-11/12'>{titleIntrotext}</h2>
          </div>
        </div>
      </div>
      <div class='container xs:p-4 md:p-0 mx-auto md:mt-8 h-auto lg:-mb-40'>
            <div class='column'>
                <div class='project-cell'>
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div id='l1' class='wrapper-img-grid img-l-1'>
                        <img src={ImgRefL1} class='img-grid' />
                        <span class='ref-label'>omnium: Smartphone-App und Web-App für das neue Jobportal.</span>
                    </div>
                  </Link>
                </div> 
                <div class="project-cell">
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div id="l2" class="wrapper-img-grid img-l-2">
                      <img src={ImgRefL2} class="img-grid" />
                      <span class="ref-label">tvtv.de: Beratung und Design für den Relaunch von tvtv.de.</span>
                    </div>
                  </Link>
                </div> 
                <div class="project-cell ">
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div class="wrapper-img-grid img-l-3">
                      <img src={ImgRefL3} class="img-grid" />
                      <span class="ref-label">KARMA: Umsetzung und Weiterentwicklung der Plattform für digitales und gesamtheitliches Gebäudemanagement.</span>
                    </div>
                  </Link>
              </div>
            </div>
            <div class="column">
                <div class="project-cell">
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div id="r1" class="wrapper-img-grid img-r-1">
                        <img src={ImgRefR1} class="img-grid" />
                        <span class="ref-label">Archipinion: Design und Entwicklung der Videoplattform für Architekt_innen.</span>
                    </div>
                  </Link>
                </div>
                <div class="project-cell">
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div id="r2" class="wrapper-img-grid img-r-2">
                        <img src={ImgRefR2} class="img-grid" />
                        <span class="ref-label">tvshows: Konzept, Design und Umsetzung der App für TV-Serienverwaltung.</span>
                    </div>
                  </Link>
                </div>
                <div class="project-cell">
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div class="wrapper-img-grid img-r-3">
                      <img src={ImgRefR3} class="img-grid" />
                      <span class="ref-label">HABA Digitalwerkstatt: Design und Umsetzung der neuen Onlineplattform</span>
                    </div>
                  </Link>
                </div>
                <div class="project-cell">
                  <div class="wrapper-img-grid img-r-4">
                    <ContactBoxSmall/>
                  </div>
                  <Link to="/projekte-infos/" state={{modal: true}}>
                    <div id="m1" class="wrapper-img-grid img-m-1">
                      <img src={ImgRefM1} class="img-grid" />
                      <span class="ref-label">Archipinion: Entwicklung der iOS-Apps für das Architekt_innen-Videoportal.</span>
                    </div>
                  </Link>
              </div>
            </div>
        </div>
        <div className='flex w-full xs:mt-0 md:-mt-8 mb-24 flex-wrap justify-center'>
        <Link to="/projekte-infos/" state={{modal: true}} withArrow className='btn transitions p-4 mr-8 xs:mt-8 md:mt-0 btn-color-text'>mehr Projekte</Link>
        </div>
    </Layout>
  )
}

export default ProjectsPage

export const projectsQuery = graphql`
  query {
    contentfulProjectsOverview(slug: {eq: "projekte"}) {
      titleStage
      titleIntrotext
      slug
      childContentfulProjectsOverviewShortStageIntroTextTextNode {
        shortStageIntroText
      }
      childContentfulProjectsOverviewShortIntrotextTextNode {
        shortIntrotext
      }
    }
    allContentfulProject {
      edges {
        node {
          title
          slug
          tasks
          thumbnail {
            fluid {
              src
            }
          }
        }
      }
    }
    allContentfulCustomer {
      edges {
        node {
          name
          referencePage
        }
      }
    }
  }
`
